import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import Layout from "../components/layout"
import Video from "../components/video"
import { TextLink } from "../components/link"
import Text from "../components/text"

const Wrapper = styled.div`
  display: grid;
`

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  margin: 0 auto;
  margin-top: 90px;
  -webkit-font-smoothing: antialiased;
  ${up("tablet")} {
    grid-template-columns: 704px;
    padding: 0 2rem;
  }
  ${up("desktop")} {
    grid-template-columns: 960px;
  }
`

const ThankYouTemplate: React.FC<{ pageContext: { copy: string } }> = ({
  pageContext: { copy },
}) => {
  return (
    <Layout>
      <Helmet>
        <title>Paybase - Thank you</title>
        <meta property="og:title" content="Paybase - Thank you" />
      </Helmet>
      <Wrapper>
        <Container>
          <Text type="h5" align="center" weight="bold">
            {copy}
          </Text>
          <Video
            src="https://www.youtube.com/embed/xRuX-Fr5TQU?rel=0&showinfo=0&controls=0"
            alt="paybase video for thank you"
            mw="100%"
            mh="480px"
            th="620px"
          />
          <p>
            If you have an online marketplace, there has been a big change in
            legislation that you need to know about. Click{" "}
            <TextLink
              to="https://paybase.io/blog/customer-agent-exemption-psd2"
              color="blue"
              hoverColor="rose"
            >
              here
            </TextLink>{" "}
            to find out more!
          </p>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default ThankYouTemplate
