import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

type Props = {
  src: string
  alt: string
  mw: string | number
  mh: string | number
  tw?: string | number
  th?: string | number
  dw?: string | number
  dh?: string | number
}

const IFRAME = styled.iframe<{
  mw: string | number
  mh: string | number
  tw?: string | number
  th?: string | number
  dw?: string | number
  dh?: string | number
}>`
  width: ${p => (typeof p.mw === "number" ? `${p.mw}px` : p.mw)};
  height: ${p => (typeof p.mh === "number" ? `${p.mh}px` : p.mh)};
  margin: 0 auto;
  ${up("tablet")} {
    width: ${p =>
      p.tw ? (typeof p.tw === "number" ? `${p.tw}px` : p.tw) : null};
    height: ${p =>
      p.th ? (typeof p.th === "number" ? `${p.th}px` : p.th) : null};
  }
  ${up("desktop")} {
    width: ${p =>
      p.dw ? (typeof p.dw === "number" ? `${p.dw}px` : p.dw) : null};
    height: ${p =>
      p.dh ? (typeof p.dh === "number" ? `${p.dh}px` : p.dh) : null};
  }
  z-index: 1;
`

const Video: React.FC<Props> = props => (
  <IFRAME
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    {...props}
  />
)

export default Video
